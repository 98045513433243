<template>
  <div class="grow flex flex-col">
    <div class="navbar bg-base-200 sticky top-0 z-10 shadow">
      <div
        class="container mx-auto px-4 flex justify-between items-center gap-2">
        <div class="space-x-2 flex justify-center gap-0.5">
          <button
            class="btn btn-primary btn-outline btn-sm btn-square ring-2"
            @click="applyLocale">
            {{ locale === "en" ? "ΕΛ" : "EN" }}
          </button>
        </div>
        <NuxtLink
          v-if="tenantName"
          :to="localeRoute('events')"
          class="text-nowrap overflow-hidden text-ellipsis max-w-[210px] md:max-w-none text-primary px-4 py-1 rounded-box glass shadow bg-gradient-to-r from-indigo-300/50 from-10% via-sky-300/40 via-30% to-emerald-300/40">
          {{ tenantName }}
        </NuxtLink>
        <UserMenu />
      </div>
    </div>
    <div class="container mx-auto p-6 pt-8">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  const { locale, setLocale } = useI18n();
  const sessionStore = useSessionStore();
  const tenantName = ref<string>();
  const localeRoute = useLocaleRoute();

  sessionStore.$subscribe((m, s) => {
    tenantName.value = s.tenant?.data.name;
  });

  const applyLocale = async () => {
    await setLocale(locale.value === "en" ? "el" : "en");
    location.reload();
  };
</script>
