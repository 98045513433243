<template>
  <div>
    <div v-if="signedIn" class="dropdown dropdown-end drop-shadow-md">
      <div
        tabindex="0"
        role="button"
        class="flex flex-nowrap items-center btn btn-primary btn-outline ring-2 btn-sm m-1">
        {{ userHandle }}
        <PhCaretDown />
      </div>
      <ul
        id="id_dropdown"
        tabindex="0"
        class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-lg w-52 mt-1 ring-2">
        <li v-if="sessionStore.isTenantUser">
          <a @click="gotoCreateEvent">
            <PhPlus />
            Create Instabooth</a
          >
        </li>
        <li>
          <a @click="gotoMyEvents">
            <PhScanSmiley />
            My Instabooths</a
          >
        </li>
        <hr class="my-1.5" />
        <li>
          <a @click="gotoSignOut">
            <ph-sign-out />
            {{ sessionStore.i18nContent.signOut }}</a
          >
        </li>
      </ul>
    </div>
    <div v-else>
      <NuxtLink
        :to="localeRoute('events-signin')"
        class="btn btn-primary btn-outline ring-2 btn-sm"
        >{{ sessionStore.i18nContent.signIn }}</NuxtLink
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useSessionStore } from "@/stores/session";
  import { ref } from "vue";
  import {
    PhCaretDown,
    PhSignOut,
    PhScanSmiley,
    PhPlus,
  } from "@phosphor-icons/vue";
  import { onAuthStateChanged } from "firebase/auth";

  const sessionStore = useSessionStore();
  const signedIn = ref(sessionStore.signedIn());
  const userHandle = ref(sessionStore.auth?.currentUser?.displayName!);
  const localeRoute = useLocaleRoute();

  onAuthStateChanged(sessionStore.auth!, () => {
    signedIn.value = sessionStore.signedIn();
    userHandle.value = sessionStore.auth?.currentUser?.displayName!;
  });

  onMounted(() => {
    if ((userHandle.value?.length ?? 0) == 0) {
      userHandle.value = sessionStore.auth?.currentUser?.displayName!;
    }
  });

  const gotoMyEvents = () => {
    navigateTo(localeRoute("events"));
    document.getElementById("id_dropdown")?.blur();
  };

  const gotoCreateEvent = () => {
    navigateTo(localeRoute("events-create"));
    document.getElementById("id_dropdown")?.blur();
  };

  const gotoSignOut = async () => {
    await sessionStore.signOut();
    navigateTo(localeRoute("events-signin"));
    document.getElementById("id_dropdown")?.blur();
  };
</script>
